import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from '../functions/isAuthenticated';

const AdminProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? <Component {...props} /> : <Redirect to={{ path: '/', state: { from: props.location } }} />
      }
    />
  );
};

export default AdminProtectedRoute;
