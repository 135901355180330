import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  crossdomain: true,
})

const refreshAuthLogic = (failedRequest) => {
  return instance
    .get('/auth/refreshtoken', {
      skipAuthRefresh: true,
    })
    .then(() => {
      return Promise.resolve()
    })
  // .catch((error) => {
  //   console.log(error.response);
  //   return Promise.reject();
  // });
}

createAuthRefreshInterceptor(instance, refreshAuthLogic)

export default instance
