import React, { Suspense, lazy } from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import Loading from "./components/Loading";
import AdminProtectedRoute from "./routes/AdminProtectedRoute";

const Home = lazy(() => import("./pages/Home"));
const CountryCheck = lazy(() => import("./pages/CountryCheck"));
const Cart = lazy(() => import("./pages/Cart"));
const Wishlist = lazy(() => import("./pages/Wishlist"));
const Login = lazy(() => import("./pages/Login"));
const GoogleCallback = lazy(() => import("./pages/GoogleCallback"));
const FacebookCallback = lazy(() => import("./pages/FacebookCallback"));
const Registration = lazy(() => import("./pages/Registration"));
const Verification = lazy(() => import("./pages/Verification"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const NewPassword = lazy(() => import("./pages/NewPassword"));
const ProductsPage = lazy(() => import("./pages/ProductsPage"));
const ProductPage = lazy(() => import("./pages/ProductPage"));
const PrivacyPolicy = lazy(() => import("./pages/informations/PrivacyPolicy"));
const TermsOfUse = lazy(() => import("./pages/informations/TermsOfUse"));
const AboutUs = lazy(() => import("./pages/informations/AboutUs"));

const AdminDashboard = lazy(() => import("./pages/admin/Dashboard"));
const AdminProducts = lazy(() => import("./pages/admin/Products"));
const AdminAddProduct = lazy(() => import("./pages/admin/AddProduct"));
const AdminCategories = lazy(() => import("./pages/admin/Categories"));
const AdminCategoryTags = lazy(() => import("./pages/admin/CategoryTags"));

const _404 = lazy(() => import("./pages/_404"));

function App() {
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Toaster position="top-right" reverseOrder={false} />
        <Switch>
          {/* Admin */}
          <AdminProtectedRoute exact path="/admin" component={AdminDashboard} />
          <AdminProtectedRoute exact path="/admin/products" component={AdminProducts} />
          <AdminProtectedRoute exact path="/admin/product/add" component={AdminAddProduct} />
          <AdminProtectedRoute exact path="/admin/categories" component={AdminCategories} />
          <AdminProtectedRoute exact path="/admin/tags/:id" component={AdminCategoryTags} />
          {/* Users */}
          <Route exact path="/login" component={Login} />
          <Route exact path="/google" component={GoogleCallback} />
          <Route exact path="/facebook" component={FacebookCallback} />
          <Route exact path="/password/reset" component={ForgotPassword} />
          <Route exact path="/password/new/:code" component={NewPassword} />
          <Route exact path="/registration" component={Registration} />
          <Route exact path="/verification/:id" component={Verification} />
          <Route exact path="/" component={CountryCheck} />
          <Route exact path="/:countryname(in|au)" component={Home} />
          <Route exact path="/:countryname(in|au)/policies/privacy" component={PrivacyPolicy} />
          <Route exact path="/:countryname(in|au)/terms" component={TermsOfUse} />
          <Route exact path="/:countryname(in|au)/about" component={AboutUs} />
          <Route exact path="/:countryname(in|au)/cart" component={Cart} />
          <Route exact path="/:countryname(in|au)/wishlist" component={Wishlist} />
          {/* Products */}
          <Route exact path="/:countryname(in|au)/:categoryname/:tagname?" component={ProductsPage} />
          {/* Single Product */}
          <Route exact path="/:countryname(in|au)/:categoryname/:tagname/:productname" component={ProductPage} />
          <Route path="/404" component={_404} />
          <Redirect to="/404" />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
