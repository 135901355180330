import React, { Fragment } from 'react';
import { Container, Image } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { FiLoader } from 'react-icons/fi';

const Loading = () => {
  return (
    <Fragment>
      <Helmet>
        <title>{`Loading... - ${process.env.REACT_APP_WEBSITE_NAME}`}</title>
      </Helmet>
      <Container className="position-relative vh-100 vw-100 overflow-hidden d-flex justify-content-center align-items-center">
        <FiLoader className="position-absolute animate-spin text-primary" size="35" />
        <Image style={{ zIndex: -1 }} className="w-75 h-75" src={process.env.PUBLIC_URL + '/images/bg.svg'} fluid />
      </Container>
    </Fragment>
  );
};

export default Loading;
