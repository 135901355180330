import instance from "./Auth";

export async function isAuthenticated() {
  await instance
    .get("/auth/check")
    .then((response) => {
      if (response.status === 200) {
        return true;
      }
    })
    .catch((error) => {
      // console.log(error);
      localStorage.clear();
      return false;
    });
}
